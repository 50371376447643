/*
 * Path: /components/TileComponents/WebsiteHeader/utils.ts
 */
export type NavItem = {
  name: string;
  slug: string;
  id: number;
  description?: string;
  dropdown?: boolean;
  items?: Array<{
    name: string;
    id: number;
    slug: string;
    description?: string;
    iconUrl?: string;
  }> | null;
  singleColumn?: boolean; // New property
};

export const navItems: NavItem[] = [
  {
    name: "How It Works",
    slug: "how-it-works",
    id: 1,
    description: "Learn about our process for buying and selling properties.",
    dropdown: false,
    items: null
  },
  {
    name: "FAQ",
    slug: "home-seller-faq",
    id: 2,
    description: "Find answers to frequently asked questions.",
    dropdown: false,
    items: null
  },
  {
    name: "Reviews",
    slug: "reviews",
    id: 3,
    description: "Read what our customers have to say about us.",
    dropdown: false,
    items: null
  }
];

export const mobileMenuNavItems: NavItem[] = [
  {
    name: "Home",
    slug: "/",
    id: 1
  },
  {
    name: "How It Works",
    slug: "how-it-works",
    id: 2
  },
  {
    name: "FAQ",
    slug: "home-seller-faq",
    id: 3
  },
  {
    name: "Reviews",
    slug: "reviews",
    id: 4
  }
];

//  GLOBAL PROMOBAR CONFIG
export const GLOBAL_PROMOBAR_TITLE = "Spring Promo";
export const GLOBAL_PROMOBAR_SUBTITLE = "Up to 25% off Design & Remodel Packages - Ends May 30th.";
export const GLOBAL_PROMOBAR_ICON = "flower";
export const SHOW_GLOBAR_PROMOBAR = false;

// Display Overlay Nav for these routes
export const OverlayedWebsiteNavPages = ["/"];

// Disable sticky nav for these routes
export const disableStickyWebsiteNavPages = [
  "/packages/virtual-styling",
  "/packages/advisor",
  "/packages/virtual-design"
];
